<div class="menu-moka">
  <ul class="menu" >
    <li *ngFor="let menu of menuList" [ngClass]="getMenuClasses(menu)" >
      <a *ngIf="menu.link && !menu.isLink" [routerLink]="menu.link" routerLinkActive="active">
        <i [class]="getIconClassMenu(menu)"></i>
        <span *ngIf="menuListExpanded" class="menu-name align-middle">{{ menu.name }}</span>
      </a>
      <a *ngIf="menu.link && menu.isLink" (click)="navigateTo(menu.link)">
        <i [class]="getIconClassMenu(menu)"></i>
        <span *ngIf="menuListExpanded" class="menu-name align-middle">{{ menu.name }}</span>
      </a>
      <a *ngIf="!menu.link" (click)="expandMenu(menu)">
        <i [class]="getIconClassMenu(menu)"></i>
        <div *ngIf="menuListExpanded" class="expanded-wrapper">
          <span class="menu-name align-middle">{{ menu.name }}</span>
          <i [class]="getIconChevronClass(menu)" style="margin-right: 10px;"></i>
        </div>
      </a>
      <ul class="submenu" *ngIf="menu.subMenus && menu.expanded && menuListExpanded">
        <li *ngFor="let submenu of menu.subMenus" class="submenu-item">
          <a *ngIf="submenu.isEnable && !submenu.isLink" [routerLink]="submenu.link" routerLinkActive="active">
            <i [class]="getIconClassSubmenu(submenu)"></i>
            <span>{{ submenu.name }}</span>
          </a>
          <a *ngIf="submenu.isEnable && submenu.isLink" (click)="navigateTo(submenu.link)">
            <i [class]="getIconClassSubmenu(submenu)"></i>
            <span>{{ submenu.name }}</span>
          </a>
          <div *ngIf="!submenu.isEnable" class="disabled">
            <i [class]="getIconClassSubmenu(submenu)"></i>
            <span>{{ submenu.name }}</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>