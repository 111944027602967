
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  baseEndpoint = this.env.apiUrlTest;

  authToken;
  httpOptions;
  httpOptionsForm;
  httpOptionsDownload;

  constructor(private httpClient: HttpClient,
    private env: EnvService,
    private authService: NbAuthService) {

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.authToken = token.getValue();
        this.setHeaders();
      }
    });
  }

  setHeaders() {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Authorization': 'Bearer ' + this.authToken
      })
    }

    this.httpOptionsForm = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Authorization': 'Bearer ' + this.authToken
      })
    }

    this.httpOptionsDownload = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Authorization': 'Bearer ' + this.authToken
      }),
      responseType: 'blob'
    }
  }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    let longMsg = ''
    let mSize = 21
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message.substring(0, mSize);
      longMsg = error.error.message
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = error.message.substring(0, mSize);
      longMsg = `Erro na Requisição. Código do erro: ${error.status}, ` + `mensagem: ${error.message}`
    }
    console.log(longMsg)
    return throwError(error.error);
  };

  async getMenus() {

    let url = '/api/mokaweb/getMenuList';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getUserMenus() {

    let url = '/api/mokaweb/getUserMenuList';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getRegisteredUsers() {

    let url = '/api/register/users';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getResources() {

    let url = '/api/powerbi/getResources';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeRegisteredUser(user) {

    let url = '/api/register/users/' + user.id;

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async createNewUser(user, resourceList, consumerList) {

    let url = '/api/register/users';

    return this.httpClient.post(this.baseEndpoint + url, { user: user, resourceList: resourceList, consumerList: consumerList }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setRegisteredUser(user, resourceList, consumerList) {

    let url = '/api/register/users/' + user.id;

    return this.httpClient.put(this.baseEndpoint + url, { user: user, resourceList: resourceList, consumerList: consumerList }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeMenu(menuId) {

    let url = '/api/mokaweb/removeMenu/' + menuId;

    // let httpParams = new HttpParams().set('id', menuId);
    // this.httpOptions.append('params', httpParams);

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setMenu(menu) {

    let url = '/api/mokaweb/setMenu';

    return this.httpClient.post(this.baseEndpoint + url, { menu: menu }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateOrderMenu(menuList) {

    let url = '/api/mokaweb/updateOrderMenu';

    return this.httpClient.put(this.baseEndpoint + url, { menuList: menuList }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeResource(resourceId) {

    let url = '/api/powerbi/removeResource/' + resourceId;

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setResource(resource) {

    let url = '/api/powerbi/setResource';

    return this.httpClient.post(this.baseEndpoint + url, { resource: resource }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getResourceHeightList() {

    let url = '/api/powerbi/getResourceHeightList';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getPowerBiAcessToken() {

    let url = '/api/powerbi/auth';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setResourceHeight(resourceHeight) {

    let url = '/api/powerbi/setResourceHeight';

    return this.httpClient.post(this.baseEndpoint + url, { resourceHeight: resourceHeight }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeResourceHeight(resourceHeightId) {

    let url = '/api/powerbi/removeResourceHeight/' + resourceHeightId;

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setCredentialData(credentialData) {

    let url = '/api/powerbi/setCredential';

    return this.httpClient.post(this.baseEndpoint + url, { credential: credentialData }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCredentialData() {

    let url = '/api/powerbi/getCredentials';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async refreshDataSet(datasetId) {

    let url = '/api/powerbi/refresh';

    return this.httpClient.post(this.baseEndpoint + url, { datasetId: datasetId }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getRefreshHistoryByQuantity(dataset_id, quantity) {

    let url = '/api/powerbi/refreshHistory';

    return this.httpClient.post(this.baseEndpoint + url, { dataset: dataset_id, quantity: quantity }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }


  async getPowerBiResource(menu) {

    let url = '/api/powerbi/getUserResources/';

    return this.httpClient.get(this.baseEndpoint + url + menu, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getVisual(visualName) {

    let url = '/api/helper/getVisual';

    return this.httpClient.post(this.baseEndpoint + url, { name: visualName }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getAllRefreshHistory(dataset_id) {

    let url = '/api/powerbi/refreshHistory';

    return this.httpClient.post(this.baseEndpoint + url, { dataset: dataset_id }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getDailyRefreshes(dataSetId) {

    let url = '/api/powerbi/getDailyRefreshes/';

    return this.httpClient.post(this.baseEndpoint + url + dataSetId, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getLoggedUser() {

    let url = '/api/logged/info';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCards(month) {

    let url;

    if (month) {
      url = '/api/cash/cards/list?month=' + month;
    } else {
      url = '/api/cash/cards';
    }

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  setInsightFeedback(insight, feedback) {

    let url = '/api/cash/insights/feedback';

    return this.httpClient.post(this.baseEndpoint + url, { insight: insight, feedback: feedback }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  /*getActiveInsights() {

    let url = '/api/cash/insights/actives'

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();

  }*/

  /*getInsightCategoryList() {

    let url = '/api/cash/insights/categories'

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }*/

  async setUserCorpAsActive(corpId) {

    let url = '/api/usercorp/active/';

    return this.httpClient.get(this.baseEndpoint + url + corpId, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getMonths() {

    let url = '/api/mp/date/months';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getExtract(start, end, place, month) {

    let url;

    if (start && end) {
      url = '/api/cash/extract/' + place + '?start=' + start + '&end=' + end;
    } else {
      url = '/api/cash/extract/' + place + '?month=' + month;
    }

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getPlaces() {

    let url = '/api/cash/places';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCategories(place, month) {


    let url = '/api/cash/categories/' + place + '?month=' + month;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getFiles() {

    let url = '/api/cash/import/files';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getSpecificFile(fileId) {

    const options = {
      responseType: 'blob',
      headers: this.httpOptions
    };


    let url = `/api/cash/import/file/download/${fileId}`;
    return this.httpClient.get(this.baseEndpoint + url, this.httpOptionsDownload)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async sendFile(file, place, account) {
    let url = '/api/extract/import/file/'
    var formData: any = new FormData()
    formData.append("files", file)
    formData.append("place_id", place)
    formData.append("bank_id", account)
    return this.httpClient.post(this.baseEndpoint + url, formData, this.httpOptionsForm)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise()
  }

  async getFinalityList() {

    let url = '/api/cash/places/finalities';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getMarketList() {

    let url = '/api/cash/places/markets';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  // CRUD CONDOMINIUMS
  async createNewCondo(condo) { // CREATE
    let url = '/api/cash/places';

    return this.httpClient.post(this.baseEndpoint + url, { place: condo }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getRegisteredCondos() { // READ
    let url = '/api/cash/places/full';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async setRegisteredCondo(condo) { // UPDATE
    let url = '/api/cash/places';

    return this.httpClient.put(this.baseEndpoint + url, { place: condo }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async removeRegisteredCondo(condo) { // DELETE
    // TODO
  }

  async getCashFlowMain(place_id, start, end, month?) {
    let url = '/api/cash/flow/' + place_id + '?start=' + start + '&end=' + end //+ '&month=' + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getCashWeeklyFlow(place_id, start, end, month?) {
    let url = '/api/cash/weeklyflow/' + place_id + '?start=' + start + '&end=' + end //+ '&month=' + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getDays(place_id) {
    let url = '/api/mp/date/days/' + place_id

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  // RECEIPTS PAGE
  async getReceiptsMain(place_id, month) {
    let url = "/api/cash/receive/" + place_id + "?month=" + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getReceiptsSecondary(place_id, month) {
    let url = "/api/cash/month/receive/" + place_id + "?month=" + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  // PAYMENTS PAGE
  async getPaymentsMain(place_id, month) {
    let url = "/api/cash/pay/" + place_id + "?month=" + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getPaymentsSecondary(place_id, month) {
    let url = "/api/cash/chart/categories/pay/" + place_id + "?month=" + month

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getConsumers() {
    let url = "/api/mp/register/consumer";

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async createConsumer(consumer) {
    let url = "/api/mp/register/consumer";

    return this.httpClient.post(this.baseEndpoint + url, { name: consumer.name, code: consumer.code, observation: consumer.observation }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async deleteConsumer(consumer_id) {
    let url = "/api/mp/register/consumer/" + consumer_id;

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateConsumer(consumer) {
    let url = "/api/mp/register/consumer/" + consumer.id;

    return this.httpClient.put(this.baseEndpoint + url, { name: consumer.name, code: consumer.code, observation: consumer.observation }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getSubmarkets() {
    let url = "/api/mp/submarket/list";

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getUnits(consumer_id) {
    let url = "/api/mp/register/unity?consumer_id=" + consumer_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async createUnit(unit) {
    let url = "/api/mp/register/unity";

    return this.httpClient.post(
      this.baseEndpoint + url,
      {
        name: unit.name,
        code: unit.code,
        address: unit.address,
        longitude: unit.longitude as number,
        latitude: unit.latitude as number,
        observation: unit.observation,
        submarket_id: unit.submarket_id as number,
        consumer_id: unit.consumer_id,
        external_id: unit.external_id
      },
      this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateUnit(unit) {
    let url = "/api/mp/register/unity/" + unit.id;

    return this.httpClient.put(this.baseEndpoint + url, unit, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async deleteUnit(unit) {
    let url = "/api/mp/register/unity/" + unit.id;

    return this.httpClient.delete(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getMonthTable(unity_id, year) {
    let url = `/api/mp/register/monthly/table?unity_id=${unity_id}&year=${year}`;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateMonthTable(table) {
    let url = '/api/mp/register/monthly/table';

    return this.httpClient.put(this.baseEndpoint + url, { monthlyRegisters: table }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async calculateFlexibilization(flexValues, table) {
    let url = "/api/mp/register/monthly/table/flex";

    return this.httpClient.put(this.baseEndpoint + url, { flexValues: flexValues, monthlyRegisters: table }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getFlexibilizationValues(unity_id) {
    let url = "/api/mp/register/flex/" + unity_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateFlexibilizationValues(unity_id, flexValues) {
    let url = "/api/mp/register/flex/" + unity_id;

    return this.httpClient.put(this.baseEndpoint + url, { flexValues: flexValues }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getConsumerCards(month) {
    let url = '/api/mp/cards/consumer?month=' + month;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async listImportedFiles() {
    let url = '/api/mp/import/files';

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async downloadFile(file_id) {
    let url = '/api/mp/import/file/download/' + file_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptionsDownload)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getUnitCards(consumer_id, month) {
    let url = '/api/mp/cards/unity/' + consumer_id + '?month=' + month;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async insertMultiScenarios(unity_id, scenarioList) {
    let url = '/api/mp/register/multi/scenarios/' + unity_id;

    return this.httpClient.put(this.baseEndpoint + url, { scenarios: scenarioList }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getDPlusOneDays(unity_id?) {
    let url = '/api/mp/date/daysdplusone/'

    if (unity_id)
      url = url + '?unity_id=' + unity_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getPLDChart(unity_id, date) {
    let url = `/api/mp/dplusone/pld/${unity_id}?date=${date}`;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getEconomyCharts(unity_id, date) {
    let url = `/api/mp/dplusone/economy/${unity_id}?date=${date}`;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getDPlusOneConsumers(user_id?) {
    let url = '/api/mp/register/consumer/dplusone';

    if (user_id)
      url = url + '?user_id=' + user_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getDPlusOneUnities(consumer_id?) {
    let url = '/api/mp/register/unity/dplusone';

    if(consumer_id)
      url = url + '?consumer_id=' + consumer_id;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getConsumptionCurves(unity_id, date) {
    let url = `/api/mp/dplusone/consumption/${unity_id}?date=${date}`;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getAssistantScenarios(unity_id) {
    let url = `/api/mp/register/assistant/${unity_id}`;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async insertAssistantScenario(unity_id, assistantList) {
    let url = `/api/mp/register/assistant/${unity_id}`;

    return this.httpClient.put(this.baseEndpoint + url, { assistantList: assistantList }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async updateAssitantData(unity_id, date) {
    let url = "/api/mp/assistant/calc";

    return this.httpClient.post(this.baseEndpoint + url, { unity_id: unity_id, date: date }, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }

  async getBestAssistantData(unity_id, date) {
    let url = `/api/mp/assistant/besteconomy/${unity_id}?date=${date}`;

    return this.httpClient.get(this.baseEndpoint + url, this.httpOptions)
      .pipe(
        retry(0),
        catchError(this.handleError)
      ).toPromise();
  }
}
