<nb-card class="insights">
  <nb-card-header class="insights-title">
    <span style="padding-left: 7px; padding-right: 7px;">Insights ativos por ambiente</span>
    <div *ngIf="insights && insights.placeList && insights.placeList.length > 0">
      <div class="filter-title">
        <span style="font-size: 0.9rem;">Filtrar por:</span>
      </div>
      <div class='row checkbox-row'>
        <div class="checkbox" (change)="filterCategories()" *ngFor='let category of categoryList'>
          <input type="checkbox" [(ngModel)]="category.selected" [disabled]="category.quantity === 0"
            class="checkbox-button" />
          <label>{{category.name}} ({{category.quantity}})</label>
        </div>
      </div>
    </div>
  </nb-card-header>
  <ngb-accordion *ngIf="filteredInsights && filteredInsights.placeList" class="environments-list" #acc="ngbAccordion"
    [closeOthers]="true" type="primary">
    <ngb-panel *ngFor="let place of filteredInsights.placeList">
      <ng-template ngbPanelTitle>
        <span class="environment-path">{{ place.path }}</span>
        <div class="row environment-box">
          <div class="col-9 environment-name">
            {{ place.name }}
          </div>
          <div class="col-3 insights-active-box">
            <div class="insights-active"> {{ place.active }}</div>
          </div>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <ul class="insights-list">
          <li class="insight" *ngFor="let insight of place.insightList; let i = index">
            <h5 class="insight-title">{{ i + 1 }}. {{ insight.title }}</h5>
            <h6 class="insight-date">{{insight.created_date | date: 'dd/MM/yyyy HH:mm'}}</h6>
            <div class="insight-message" [innerHTML]="insight.message | safeHtml"></div>
            <ul class="feedbacks-list">
              <li class="feedback" *ngFor="let feedback of insight.feedback">
                <button (click)="onFeedbackClick(insight, place.insightList, place, feedback)"
                  class=" btn feedback-name">{{ feedback.name }}</button>
              </li>
            </ul>
          </li>
        </ul>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <nb-card-body *ngIf="!insights || !insights.placeList || insights.placeList.length <= 0">
    <p><i>Não existem insights no momento</i></p>
  </nb-card-body>
  <nb-card-body *ngIf="insights && insights.placeList && insights.placeList.length > 0 
                          && filteredInsights && filteredInsights.placeList.length <= 0">
    <p><i>Nenhum filtro aplicado. Por favor, selecione alguma opção.</i></p>
  </nb-card-body>
</nb-card>