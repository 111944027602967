import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { RippleService } from '../../../@core/utils/ripple.service';
import { ServerService } from '../../../services/server.service';
import { BodyOutputType, Toast, ToasterConfig, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  categoryList: any = [];
  insights: any = [];
  showInsights = false;
  loading: boolean = false;
  refreshInterval: any;
  counter: number = 600;

  user: any = {name : '', picture: ''};
  hasCorp: boolean
  corp: any = {}
  corps: any = []


  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'material-light',
      name: 'Material Light',
    },
    {
      value: 'material-dark',
      name: 'Material Dark',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Log out'}];

  toasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 5000,
    newestOnTop: true,
    tapToDismiss: true,
    preventDuplicates: false,
    showCloseButton: true,
    animation: 'flyRight',
    limit: 5,
  });

  public constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private rippleService: RippleService,
    private serverApi: ServerService,
    private toasterService: ToasterService
  ) {
    this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(map(theme => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }));
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

      this.user.name = JSON.parse(localStorage.getItem('username'));
      this.user.picture = JSON.parse(localStorage.getItem('userImg'));
      if (localStorage.getItem('corp')) {
        this.corps = JSON.parse(localStorage.getItem('usercorps'))
        this.corp = JSON.parse(localStorage.getItem('corp'));
        this.hasCorp = true
      }

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
      });

    /*this.getInsights();
    this.getInsightCategoryList();*/
  }

  ngAfterViewInit() {
    this.enableInsightsRefresh()
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.disableInsightsRefresh()

  }

  private showToast(type, title, body) {

    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };

    this.toasterService.popAsync(toast);
  }


  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  disableInsightsRefresh() {
    clearInterval(this.refreshInterval)
  }

  onInsightsClick() {
    this.showInsights = !this.showInsights
    this.showInsights === false ? this.enableInsightsRefresh() : this.disableInsightsRefresh()
  }

  closeInsights() {
    this.showInsights = false
    this.enableInsightsRefresh()
  }

  /*getInsightCategoryList() {

    this.serverApi.getInsightCategoryList().then(data => {
      let serverResponse: any = data;

      if (serverResponse.status === 0) {
        this.categoryList = serverResponse.insightsCategoryList;

        // this.categoryList.map((category) => { category.selected = true; category.quantity = 0 });
      }
    });
  }*/

  /*getInsights() {
    this.loading = true;
    let serverResponse;
    this.serverApi.getActiveInsights().then(data => {
      serverResponse = data;
      if (serverResponse.status === 1) {
        this.showToast("error", "Erro", "Ocorreu algum erro ao carregar os insights ativos.")
      }
      else {
        this.insights = serverResponse;
        this.insights.active = this.getTotalActiveInsights();
      }
      this.loading = false;
    })
  }*/

  getTotalActiveInsights() {
    let count = 0;
    for(let place of this.insights.placeList) {
      count += place.insightList.length
    }
    return count;
  }

  enableInsightsRefresh() {
    this.refreshInterval = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter === 0) {
        this.counter = 600
        //this.getInsights()
      }
    }, 1000)
  }
}
