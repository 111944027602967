import { Component, OnInit, Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ToasterConfig, ToasterService, BodyOutputType, Toast } from 'angular2-toaster';
import { EventEmitterService } from '../services/event-emitter.service';

@Component({
  selector: 'ngx-interceptor',
  template: `<toaster-container [toasterconfig]="toasterConfig"></toaster-container>`
})
@Injectable()
export class InterceptorComponent implements OnInit, HttpInterceptor {
  toasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 5000,
    newestOnTop: true,
    tapToDismiss: true,
    preventDuplicates: false,
    showCloseButton: true,
    animation: 'flyRight',
    limit: 5,
  });

  constructor(public toasterService: ToasterService) { }

  ngOnInit(): void { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse && (evt.body && evt.body.success)) {
            // this.showToast("success", evt.body.success.title, evt.body.success.message);
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && !req.body.login) {
          if (err.error.message) {
            this.showToast("error", "Erro "+((err.error.code)?(err.error.code):(err.status)), err.error.message);
          } else {
            this.showToast("error", "Erro "+((err.error.code)?(err.error.code):(err.status)), "Ocorreu algum erro durante a requisição");
          }
          //log error
        } else if (err instanceof HttpErrorResponse && req.body.login) {
          EventEmitterService.get('loginError').emit();
        }
        return of(err);
      }));
  }

  private showToast(type, title, body) {

    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };

    this.toasterService.popAsync(toast);
  }
}
