import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NbLogoutComponent, NbTokenService } from '@nebular/auth';
import { NbAuthService } from '@nebular/auth';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
})
export class NgxLogoutComponent extends NbLogoutComponent {

    constructor(public authService: NbAuthService, router: Router, public tokenService: NbTokenService){
        super(authService, {}, router);
    }

    logout() {     
        this.authService.logout('login');
        this.tokenService.clear();
        this.router.navigate(['auth/login']);   
    }

}