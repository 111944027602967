import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';
import { NgxLoginComponent } from './login/login.component';
import { NgxLogoutComponent } from './logout/logout.component';

export const routes: Routes = [
    // {
    //     path: 'auth',
    //     loadChildren: './auth/auth.module#NgxAuthModule',
    // },
    {
        path: '',
        component: NbAuthComponent,
        children: [
            {
                path: '',
                component: NgxLoginComponent,
              },
              {
                path: 'login',
                component: NgxLoginComponent,
              },
              {
                path: 'logout',
                component: NgxLogoutComponent,
              },
        ],
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxAuthRoutingModule {
}