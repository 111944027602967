import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import {
  NbCardModule, NbChatModule, NbCheckboxModule, NbDatepickerModule,
  NbDialogModule, NbLayoutModule, NbMenuModule, NbRouteTabsetModule, NbSelectModule, NbSidebarModule, NbSpinnerModule, NbTabsetModule, NbToastrModule, NbTooltipModule, NbWindowModule
} from '@nebular/theme';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToasterModule } from 'angular2-toaster';
import { NgxEchartsModule } from 'ngx-echarts';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth-guard.service';
import { NgxAuthModule } from './auth/auth.module';
import { EnvServiceProvider } from './env.service.provider';
import { InterceptorComponent } from './interceptor/interceptor.component';


registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, InterceptorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    NbTooltipModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    NgbTooltipModule,
    FormsModule,
    NbSelectModule,
    ReactiveFormsModule,
    ThemeModule.forRoot(),
    NbCardModule,
    NbRouteTabsetModule,
    NbLayoutModule,
    NbTabsetModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NgxAuthModule,
    NbCheckboxModule,
    NbSpinnerModule,
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'login',
          token: {
            class: NbAuthJWTToken,
            key: 'token', // this parameter tells where to look for the token
          },
          baseEndpoint: 'https://mpapi.mokacloud.com.br',
          login: {
            endpoint: '/auth/loginWeb',
            method: 'post'
          },
        }),
      ],
      forms: {
        login: {
          redirectDelay: 500, // delay before redirect after a successful login, while success message is shown to the user
          strategy: 'login',  // strategy id key.
          rememberMe: true,   // whether to show or not the `rememberMe` checkbox
          showMessages: {     // show/not show success/error messages
            success: true,
            error: true,
          },
          logout: {
            redirectDelay: 500,
            strategy: 'login',
          },
          validation: {
            password: {
              required: true,
              minLength: 4,
              maxLength: 50,
            }
          }
        },
      },
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ToasterModule
  ],
  bootstrap: [AppComponent],
  providers: [
    EnvServiceProvider,
    AuthGuard,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorComponent, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" }
  ],
})
export class AppModule {
}
