// import { LayoutService } from './../../../@core/data/layout.service';
import { Component, ViewEncapsulation, OnInit, OnDestroy, ElementRef, AfterViewInit, Renderer2, HostListener } from '@angular/core';
import { EventEmitterService } from './../../../services/event-emitter.service';
import { NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';
import { ServerService } from '../../../services/server.service'
import { Router } from '@angular/router';
// import { ToasterService, BodyOutputType, Toast } from 'angular2-toaster';
import { icon } from 'leaflet';
import { Location } from '@angular/common';

@Component({
  selector: 'ngx-moka-menu',
  templateUrl: './moka-menu.component.html',
  styleUrls: ['./moka-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MokaMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  menuList: any[] = [];
  menuListLength: number;
  alive: boolean = true;
  selectedItem: string;
  updateCorp: any;
  loggedUser: any;
  isAdmin: boolean = false;
  user
  home: string = '';
  menuListExpanded: boolean = true;

  constructor(private renderer: Renderer2,
    private serverService: ServerService,
    private location: Location,
    private elRef: ElementRef,
    private sidebarService: NbSidebarService,
    // private layoutService: LayoutService,
    private router: Router,
    private serverApi: ServerService,
    private menuService: NbMenuService,
    // private toasterService: ToasterService
  ) {

    EventEmitterService.get('updateCorp').subscribe(data => this.getMenu())
    EventEmitterService.get('updateMenu').subscribe(data => this.updateMenu())

    this.sidebarService.onToggle().subscribe(data => this.toggleMenu());
  }

  ngOnInit() {
    this.getLoggedUser()
    this.getMenu()
  }

  //Em telas entre 575px e 1200px o comportamento do toggle é diferente. A verificacao abaixo deve ser feita
  ngAfterViewInit() {
    let innerWidth = window.innerWidth;
    if (innerWidth > 575 && innerWidth < 1200) {
      let ele = this.elRef.nativeElement.parentElement.parentElement.parentElement;
      this.menuListExpanded = false;
      this.renderer.addClass(ele, 'hide-sidebar');
    }
  }

  getLoggedUser() {
    let serverResponse
    this.serverApi.getLoggedUser().then(data => {
      serverResponse = data
      if (serverResponse.status === 1) {
        // this.showToast('error', 'Erro', 'Erro ao obter os dados do usuário.')
      }
      else {
        this.loggedUser = serverResponse.user
        this.isAdmin = serverResponse.user.webAdmin
      }
    })
  }

  getMenu() {
    let serverResponse
    this.serverApi.getUserMenus().then(data => {
      serverResponse = data
      if (serverResponse.status === 1) {
        if (serverResponse.menuList && serverResponse.menuList.length === 0) {
          this.createMenu(serverResponse.menuList);
        }
        else {
          // this.showToast('error', 'Erro', 'Ocorreu algum erro ao carregar o menu na side-bar')
        }
      }
      else {
        this.createMenu(serverResponse.menuList)
      }
    })
  }



  updateMenu() {
    this.isAdmin = false
    this.menuList = []
    // this.getLoggedUser()
    this.getMenu()
  }

  async createMenu(menuList) {
    let corp = JSON.parse(localStorage.getItem('corp'))
    let isSmartClass: boolean = false;
    let createAdmin: boolean = true
    this.menuList = []
    if (corp && (corp.hasOwnProperty('modules') && corp.modules.hasOwnProperty('smartClass'))) {
      isSmartClass = corp.modules.smartClass

    }

    for (const m of menuList) {

      let subMenuItems = []
      let expanded = false;

      if (m.isMobile) {
        continue;
      }

      if (m.reg_id === 'admin') {
        createAdmin = false
        //Se a empresa tem o módulo smartClass, adiciona o cadastro do smartClass no menu
        if (isSmartClass) {
          this.createSmartClass(subMenuItems)
        }
      }

      if (m.subMenuList.length > 0) {

        for (const sbm of m.subMenuList) {

          var n = this.router.url.indexOf('?');
          var urlSubstring = this.router.url.substring(0, n != -1 ? n : this.router.url.length);

          if (urlSubstring === sbm.link) {
            expanded = true;
          }


          if (urlSubstring === "/pages/select-corporation" && sbm.home === true) {
            expanded = true;
          }

          let linkSbm: string;

          if (sbm.isReport) {
            linkSbm = "/pages/report-power-bi/" + sbm.reg_id;
          }
          else if (sbm.isIframe) {
            linkSbm = "/pages/iframe/" + sbm.reg_id;
          }
          else if (sbm.isLink) {
            linkSbm = await this.formatLinkParams(sbm.linkSrc);
          }
          else {
            linkSbm = sbm.link;
          }

          subMenuItems.push({
            name: sbm.name,
            reg_id: sbm.reg_id,
            link: linkSbm,
            isAdmin: m.isAdmin,
            isEnable: sbm.isEnable,
            isReport: sbm.isReport,
            isIframe: sbm.isIframe,
            isLink: sbm.isLink,
            home: sbm.home,
            icon: sbm.icon
          });
        }
      }

      let linkMenu: string;

      if (m.isReport) {
        linkMenu = "/pages/report-power-bi/" + m.reg_id;
      }
      else if (m.isIframe) {
        linkMenu = "/pages/iframe/" + m.reg_id;
      }
      else if (m.isLink) {
        linkMenu = await this.formatLinkParams(m.linkSrc);
      }
      else {
        linkMenu = m.link;
      }

      this.menuList.push({
        reg_id: m.reg_id,
        name: m.name,
        icon: m.icon,
        link: linkMenu,
        subMenus: m.subMenuList.length > 0 ? subMenuItems : null,
        expanded: expanded,
        isAdmin: m.isAdmin,
        isEnable: m.isEnable,
        isMobile: m.isMobile,
        isReport: m.isReport,
        isIframe: m.isIframe,
        isLink: m.isLink,
        home: m.home
      });
    }

    // if ((createAdmin || menuList.length === 0) && this.isAdmin) {
    //   this.getAllMenusLength();
    // }
    this.createMenuLogout()
    this.expandMenuInSession();
  }

  async formatLinkParams(link: any) {
    let username;
    await this.serverService.getLoggedUser().then(data => {
      let serverResponse: any = data;
      username = serverResponse.user.login;
    });

    let newLink: string = link;
    let params = link.split('?')[1];
    if (params) {
      for (let param of params.split('&')) {
        let [key, value]: string[] = param.split('=');
        if (key == 'username') {
          newLink = link.replace(value, username);
        }
      }
    }

    return newLink;
  }

  createSmartClass(subMenuItems) {
    subMenuItems.push({
      reg_id: 'register-smart-class',
      name: 'Cadastro Smart Class',
      isReport: false,
      isIframe: false,
      isLink: false,
      isEnable: true,
      isAdmin: true,
      isMobile: false,
      link: '/pages/admin/register-smart-class',
    });
  }

  createMenuLogout() {
    this.menuList.push({
      reg_id: 'logout',
      name: 'Logout',
      icon: 'fas fa-sign-out-alt',
      isReport: false,
      isIframe: false,
      isLink: false,
      isEnable: true,
      isAdmin: false,
      isMobile: false,
      link: '/auth/logout',
      class: "menu-logout"
    })
  }

  // private showToast(type, title, body) {

  //   const toast: Toast = {
  //     type: type,
  //     title: title,
  //     body: body,
  //     bodyOutputType: BodyOutputType.TrustedHtml,
  //   };

  //   // this.toasterService.popAsync(toast);
  // }

  ngOnDestroy() {
    this.alive = false;
    this.isAdmin = false;
  }

  getIconClassMenu(menu) {
    return 'icon-menu align-top ' + menu.icon;
  }
  getIconClassSubmenu(submenu) {
    return 'icon-submenu align-top ' + submenu.icon;
  }
  getIconChevronClass(menu) {
    if (menu.expanded) {
      return 'expansion-icon ion chevron ion-chevron-down';
    }
    else {
      return 'expansion-icon ion-chevron-left';
    }
  }


  expandMenuInSession() {
    let menuIdInSession = sessionStorage.getItem('menuExpanded') as Object;
    if (sessionStorage.getItem('menuExpanded')) {
      let menu = this.menuList.find(m => m.reg_id === menuIdInSession);
      if (menu) {
        this.expandMenu(menu);
      }
    }
  }

  expandMenu(menu) {
    menu.expanded = !menu.expanded;
    if (menu.expanded) {
      sessionStorage.setItem('menuExpanded', menu.reg_id);
    }
    else {
      sessionStorage.removeItem('menuExpanded');
    }
    this.toggleSidebar();

  }

  toggleMenu() {
    let innerWidth = window.innerWidth;
    let elfRef = this.elRef.nativeElement;
    let ele = elfRef.parentElement.parentElement.parentElement;
    if (innerWidth > 575 && innerWidth <= 1199) {
      if (this.menuListExpanded) {
        this.renderer.addClass(ele, 'hide-sidebar');
        this.menuListExpanded = false;
      }
      else {
        this.renderer.removeClass(ele, 'hide-sidebar');
        this.menuListExpanded = true;
      }
    }
    else if (innerWidth >= 1200) {
      if (this.menuListExpanded) {
        this.renderer.addClass(elfRef, 'padding-menu-collapsed');
        this.menuListExpanded = false;
      }
      else {
        this.renderer.removeClass(elfRef, 'padding-menu-collapsed');
        this.menuListExpanded = true;
      }
    }
  }

  toggleSidebar() {
    let elfRef = this.elRef.nativeElement;
    let innerWidth = window.innerWidth;
    if (innerWidth >= 1200) {
      if (!this.menuListExpanded) {
        this.renderer.removeClass(elfRef, 'padding-menu-collapsed');
        this.menuListExpanded = true;
        this.sidebarService.expand('menu-sidebar');
        // this.layoutService.changeLayoutSize();

        // if ((window.innerHeight + window.scrollY +23) >= document.body.offsetHeight) {
        //   EventEmitterService.get('bottomScrolled').emit(true);
        // } else {
        //   EventEmitterService.get('bottomScrolled').emit(false);
        // }
      }
    }
  }

  getMenuClasses(menu) {
    if (menu.class) {
      return "menu-item " + menu.class;
    }
    else {
      return "menu-item";
    }
  }

  geSubmenuClasses(submenu) {
    if (submenu.class) {
      return "submenu-item " + submenu.class;
    }
    else {
      return "submenu-item";
    }
  }


  getAllMenusLength() {
    let serverResponse;
    this.serverApi.getMenus().then(data => {
      serverResponse = data
      if (serverResponse.status === 1) {
        // this.showToast('error', 'Erro', 'Ocorreu algum erro ao carregar a lista de menus')
      }
      else {
        this.menuListLength = serverResponse.menuList.length;
        this.createMenuAdmin();
        setTimeout(() => {
          this.updateMenu();
          // EventEmitterService.get('updateMenuInRegisterMenu').emit();
        }, 700);
      }
    })
  }

  createMenuAdmin() {
    let loggedUser = [{
      username: this.loggedUser.login,
      _id: this.loggedUser._id
    }]
    let subMenus = [{
      _id: '',
      reg_id: 'register-menu',
      name: 'Cadastro de Menus',
      isReport: false,
      isIframe: false,
      isLink: false,
      isEnable: true,
      isAdmin: true,
      isMobile: false,
      link: '/pages/admin/register-menu',
      usersList: loggedUser
    },
    {
      _id: '',
      reg_id: 'reports-power-bi',
      name: 'Resources PowerBI',
      isReport: false,
      isIframe: false,
      isLink: false,
      isEnable: true,
      isAdmin: true,
      isMobile: false,
      link: '/pages/admin/reports-power-bi',
      usersList: loggedUser
    },
    {
      _id: '',
      reg_id: 'register-user',
      name: 'Cadastro de Usuários',
      isReport: false,
      isIframe: false,
      isLink: false,
      isEnable: true,
      isAdmin: true,
      isMobile: false,
      link: '/pages/admin/register-user',
      usersList: loggedUser
    }]

    let menu = {
      _id: '',
      reg_id: 'admin',
      name: 'Admin',
      icon: 'fas fa-edit',
      isReport: false,
      isIframe: false,
      isLink: false,
      isEnable: true,
      isAdmin: true,
      isMobile: false,
      link: '',
      subMenuList: subMenus,
      usersList: loggedUser,
      order: this.menuListLength
    }
    let serverResponse
    this.serverApi.setMenu(menu).then(data => {
      serverResponse = data
      if (serverResponse.status === 1) {
        if (serverResponse.user_message) {
          // this.showToast('error', 'Erro', serverResponse.user_message)
        }
        else {
          // this.showToast('error', 'Erro', 'Ocorreu algum erro ao salvar os dados')
        }
      }
    })
  }

  navigateTo(link) {
    window.open(link, "_blank");
  }
}
