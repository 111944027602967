import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbMenuService } from '@nebular/theme';
import { AnalyticsService } from './@core/utils/analytics.service';
import { ServerService } from './services/server.service';
import { ToasterConfig } from 'angular2-toaster';

@Component({
  selector: 'ngx-app',
  template: `
  <toaster-container [toasterconfig]="toasterConfig"></toaster-container>
  <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  toasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 5000,
    newestOnTop: true,
    tapToDismiss: true,
    preventDuplicates: false,
    showCloseButton: true,
    animation: 'flyRight',
    limit: 5,
  });

  constructor(private analytics: AnalyticsService, private serverService: ServerService, public menuService: NbMenuService, private authService: NbAuthService, private router: Router) {

    this.menuService.onItemClick()
    .subscribe((event) => {
      this.onContecxtItemSelection(event.item.title);
    });

  }

  onContecxtItemSelection(title) {
    
    if (title === 'Log out') {
      this.router.navigate(['auth/logout']);
    }

  }

  ngOnInit() {
    this.analytics.trackPageViews();
  }
}
