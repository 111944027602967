import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { ServerService } from '../../../services/server.service';

@Component({
  selector: 'ngx-insights',
  templateUrl: './insights.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./insights.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class InsightsComponent implements OnInit {

  @Input() insights
  @Input() categoryList
  @Output() closeInsights = new EventEmitter<boolean>()
  @Output() refresh = new EventEmitter()

  loading: boolean = false
  clickCounter: number = 0

  filteredInsights: any;
  totalFilteredInsights: any = 0;
  totalInsightsByCategory: any = [];
  quantityCalculated: boolean = false;
  public checkboxValue: boolean;

  constructor(private _eref: ElementRef,
    private serverApi: ServerService,
    private toasterService: ToasterService,
    private router: Router) {
    this.checkboxValue = true;
    this.categoryList = [{ name: 'Conforto', selected: true, category: "comfort", quantity: 0 }, { name: 'Consumo', selected: true, category: 'consumption', quantity: 0 }, { name: 'Relatório', selected: true, category: 'report', quantity: 0 }, { name: 'Monitoramento', selected: true, category: 'monitor', quantity: 0 }, { name: 'Serviço', selected: false, category: 'service', quantity: 0 }];
  }

  ngOnInit(): void {

    this.categoryList.map((category) => { category.selected = true; category.quantity = 0 });
    this.filterCategories();
  }

  filterCategories() {

    this.totalFilteredInsights = 0;

    // Efetua uma cópia dos insights
    this.filteredInsights = JSON.parse(JSON.stringify(this.insights));

    // Filtra as categorias que estão selecionadas no checkbox
    let selectedCategories = this.categoryList.filter((category) => category.selected === true);

    let filteredList: any = [];

    for (var i = 0; i < this.filteredInsights.placeList.length; i++) {

      let insight = this.filteredInsights.placeList[i].insightList.filter((insight) => {
        insight.message = insight.message.replace(/\\n/g, "<br>");
        insight.message = insight.message.replace(/\\/g, "")

        return selectedCategories.some((category) => {
          // Adicionar em um array todas as categorias
          let categoryName = category['category']
          if (insight.type.includes(categoryName)) {
            this.totalInsightsByCategory.push(categoryName);
            return true;
          }
          else return false
        });
      });

      let places: any = JSON.parse(JSON.stringify(this.filteredInsights.placeList));
      delete places[i].insightList;

      if (insight && insight.length > 0) {
        filteredList.push(places[i]);
        filteredList[filteredList.length - 1].insightList = insight;
        filteredList[filteredList.length - 1].active = insight.length;
      }
    }

    this.filteredInsights.placeList = filteredList;

    // Contador da quantidade total de insights filtrados
    for (var i = 0; i < this.filteredInsights.placeList.length; i++) {
      this.totalFilteredInsights += this.filteredInsights.placeList[i].active;
    }

    // Contador da quantidade total de cada insight
    if (!this.quantityCalculated) {

      for (let i = 0; i < this.categoryList.length; i++) {
        for (let j = 0; j < this.totalInsightsByCategory.length; j++) {
          if (this.categoryList[i].category === this.totalInsightsByCategory[j]) {
            this.categoryList[i].quantity++;
          }
        }
      }

      // Seta o checkbox como false para todas as categorias sem insights
      this.categoryList.map((category) => { if (category.quantity === 0) category.selected = false; });

      this.quantityCalculated = true;
    }
  }

  onClick(event) {

    //verificar se é o primeiro click. Se não for, aí fecha a lista de insights
    if (this.clickCounter > 0) {
      if (!this._eref.nativeElement.contains(event.target)) {
        this.closeInsights.emit()
      }
    }
    else {
      this.clickCounter++
    }
  }
  //Envia o feedback para o back-end e remove da lista de notificações
  onFeedbackClick(insight, insightList, place, feedback) {

    let serverResponse;

    this.serverApi.setInsightFeedback(insight, feedback).then(data => {
      serverResponse = data;

      if (serverResponse.status === 1) {
        this.showToast('error', 'Erro', 'Ocorreu algum erro ao enviar o feedback')

      } else {
        let ins = (insightList.find(i => i.id === insight.id));
        let index = insightList.indexOf(ins);
        insightList.splice(index, 1);

        for (let i = 0; i < this.insights.placeList.length; i++) {
          for (let j = 0; j < this.insights.placeList[i].insightList.length; j++) {
            if (this.insights.placeList[i].insightList[j].id === ins.id) {
              this.insights.placeList[i].insightList.splice(j, 1);
              break;
            }
          }
        }

        this.insights.active--;
        place.active--;

        for (let i = 0; i < this.categoryList.length; i++) {
          if (insight.type.includes(this.categoryList[i].category)) {
            this.categoryList[i].quantity--;
            if (this.categoryList[i].quantity === 0) {
              this.categoryList[i].selected = false;
            }
            break;
          }
        }

        for (let i = 0; i < this.insights.placeList.length; i++) {
          if (this.insights.placeList[i].insightList.length <= 0) {
            this.insights.placeList.splice(i, 1);
          }
        }

        if (this.insights.placeList.length <= 0) {
          this.insights = [];
        }

        if (feedback.url) this.router.navigateByUrl(feedback.url);
        this.filterCategories();

      }
    });
  }


  private showToast(type, title, body) {

    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };

    this.toasterService.popAsync(toast);
  }

}
