import { Component } from '@angular/core';
import { NbLoginComponent } from '@nebular/auth';
import { Md5 } from 'ts-md5/dist/md5'
import { UserService } from '../../@core/mock/users.service';
import { EventEmitterService } from '../../services/event-emitter.service';

@Component({
    selector: 'ngx-login',
    styleUrls: ['./login.component.scss'],
    templateUrl: './login.component.html'
})
export class NgxLoginComponent extends NbLoginComponent {
    loginError = false;

    user = {
        'username': '',
        'passwd': ''
    };
    input = {
        'username': '',
        'passwd': ''
    };

    makeLogin() {
        this.loginError = false;

        this.user = JSON.parse(JSON.stringify(this.input));
        let uncryptedPassword = this.user.passwd;
        let encryptedPassword = JSON.parse(JSON.stringify(this.user.passwd));
        encryptedPassword = Md5.hashStr(encryptedPassword);
        this.user.passwd = encryptedPassword;

        EventEmitterService.get('loginError').subscribe(data => {
            this.loginError = true;
        });

        this.service.authenticate('login', { username: this.user.username, passwd: this.user.passwd, login: true }).subscribe(response => {

            let resp: any = response;

            super.login();
            this.user.passwd = uncryptedPassword;

            if (resp.success) {
                localStorage.removeItem('corp')
                localStorage.removeItem('userImg')
                localStorage.setItem('username', JSON.stringify(resp.response.body.user.name));
                if (resp.response.body.user.image)
                    localStorage.setItem('userImg', JSON.stringify(resp.response.body.user.image.path));
                localStorage.setItem('usercorps', JSON.stringify(resp.response.body.userCorp));
                localStorage.setItem('role', JSON.stringify(resp.response.body.user.webAdmin));
            }
        });
    }
}